@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BannerSection {
  position: relative;
}
.BannerSection .img-container {
  height: 300px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
.BannerSection .overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(233, 159, 140, 0.4431372549);
}
.BannerSection .text h2 {
  color: #F5EE9B;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 64px;
  z-index: 1;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 992px) {
  .BannerSection .text h2 {
    font-size: 55px;
  }
}
@media all and (max-width: 768px) {
  .BannerSection .text h2 {
    font-size: 50px;
  }
}
@media all and (max-width: 576px) {
  .BannerSection .text h2 {
    font-size: 50px;
  }
}