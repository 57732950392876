@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.BlogDetails {
  position: relative;
  background-color: rgb(255, 248, 248);
}
.BlogDetails .container {
  padding: 50px 100px;
  /* Responsive */
}
@media all and (min-width: 2000px) {
  .BlogDetails .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .BlogDetails .container {
    padding: 50px 100px;
  }
}
@media all and (max-width: 992px) {
  .BlogDetails .container {
    padding: 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .BlogDetails .container {
    padding: 60px 32px;
  }
}
@media all and (max-width: 576px) {
  .BlogDetails .container {
    padding: 60px 16px;
  }
}
.BlogDetails .container .wp-block-image img {
  max-width: 100%;
  min-width: 200px;
  height: 100% !important;
  padding: 16px 0;
}
.BlogDetails .container .wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
@media all and (max-width: 576px) {
  .BlogDetails .container .wp-block-gallery {
    justify-content: center;
  }
}
.BlogDetails .container .wp-block-gallery figure {
  margin: 5px;
}
.BlogDetails .container .wp-block-gallery img {
  max-width: 300px;
  min-width: 20px;
}
.BlogDetails .container p {
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 150%;
  margin-left: 0;
  margin-right: 0;
  font-size: 17px;
  line-height: 160%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .BlogDetails .container p {
    font-size: 16px;
  }
}
.BlogDetails .container .wp-block-button a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
}
.BlogDetails .container .wp-block-button a:hover {
  background-color: white;
  opacity: 1;
}
.BlogDetails .container .wp-block-button a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.BlogDetails .container .wp-block-button a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.BlogDetails .container .wp-block-button a:first-child {
  transition: transform 0.3s ease;
  /* Optional: Add a smooth transition effect */
}
.BlogDetails .container h2 {
  color: #b45250;
}
.BlogDetails .container ol {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.BlogDetails .container pre {
  margin: 1em 0;
}
.BlogDetails .container ul {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 40px;
}
.BlogDetails .container blockquote {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}
.BlogDetails .container dd {
  margin-left: 40px;
}
.BlogDetails .container dl {
  margin-top: 1em;
  margin-bottom: 1em;
}
.BlogDetails .container fieldset {
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.BlogDetails .container figure {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 40px;
  margin-right: 40px;
}
.BlogDetails .container h2 {
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.BlogDetails .container h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.BlogDetails .container h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.BlogDetails .container h5 {
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.BlogDetails .container h6 {
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
.BlogDetails .container hr {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
}
.BlogDetails .container video {
  max-width: 80%;
  width: 600px;
  margin: 0;
  border: 1px solid #ccc;
  /* Add a border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.658);
  /* Add a subtle shadow */
}
.BlogDetails .container iframe {
  max-width: 100%;
  margin: 0;
}
.BlogDetails .container table {
  margin: 60px 0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}
.BlogDetails .container table td,
.BlogDetails .container table th {
  text-align: center;
  padding: 14px;
}
.BlogDetails .container table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}
.BlogDetails .container table thead th {
  color: #ffffff;
  background: #4FC3A1;
}
.BlogDetails .container table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}
.BlogDetails .container table tr:nth-child(even) {
  background: #F8F8F8;
}
@media (max-width: 767px) {
  .BlogDetails .container table {
    display: block;
    width: 100%;
  }
  .BlogDetails .container .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  .BlogDetails .container table thead,
  .BlogDetails .container table tbody,
  .BlogDetails .container table thead th {
    display: block;
  }
  .BlogDetails .container table thead th:last-child {
    border-bottom: none;
  }
  .BlogDetails .container table thead {
    float: left;
  }
  .BlogDetails .container table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .BlogDetails .container table td,
  .BlogDetails .container table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .BlogDetails .container table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .BlogDetails .container table tbody tr {
    display: table-cell;
  }
  .BlogDetails .container table tbody tr:nth-child(odd) {
    background: none;
  }
  .BlogDetails .container table tr:nth-child(even) {
    background: transparent;
  }
  .BlogDetails .container table tr td:nth-child(odd) {
    background: #F8F8F8;
    border-right: 1px solid #E6E4E4;
  }
  .BlogDetails .container table tr td:nth-child(even) {
    border-right: 1px solid #E6E4E4;
  }
  .BlogDetails .container table tbody td {
    display: block;
    text-align: center;
  }
}

.p-skeleton-loading {
  padding: 50px 100px;
  background-color: rgb(255, 248, 248);
}
@media all and (min-width: 2000px) {
  .p-skeleton-loading {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .p-skeleton-loading {
    padding: 50px 100px;
  }
}
@media all and (max-width: 992px) {
  .p-skeleton-loading {
    padding: 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .p-skeleton-loading {
    padding: 60px 32px;
  }
}
@media all and (max-width: 576px) {
  .p-skeleton-loading {
    padding: 60px 16px;
  }
}
.p-skeleton-loading .p-loading .line {
  width: 90%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}
.p-skeleton-loading .p-loading .line:last-child {
  width: 70%;
}
.p-skeleton-loading .headline_loading {
  width: 50%;
  margin-top: 40px;
  height: 40px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
  /* Optional: Add border-radius for rounded corners */
  margin-bottom: 10px;
  text-indent: -9999px;
}