@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.FaceIntro {
  position: relative;
  background-color: #E99F8C;
  overflow: hidden;
}
.FaceIntro .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .FaceIntro .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .FaceIntro .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .FaceIntro .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .FaceIntro .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .FaceIntro .container {
    padding: 50px 14px;
  }
}
.FaceIntro .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* .right-col animation styling */
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row {
    flex-direction: column;
  }
}
.FaceIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.FaceIntro .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .FaceIntro .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.FaceIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.FaceIntro .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .FaceIntro .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.FaceIntro .container .row .left-col {
  width: 60%;
  z-index: 2;
  position: relative;
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .left-col {
    width: 100%;
    text-align: center;
  }
}
.FaceIntro .container .row .left-col h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  color: #b45250;
}
@media all and (max-width: 1200px) {
  .FaceIntro .container .row .left-col h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .FaceIntro .container .row .left-col h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .FaceIntro .container .row .left-col h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .left-col h2 {
    font-size: 24px;
  }
}
.FaceIntro .container .row .left-col p {
  font-size: 17px;
  line-height: 160%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: white;
}
@media all and (max-width: 992px) {
  .FaceIntro .container .row .left-col p {
    font-size: 16px;
  }
}
.FaceIntro .container .row .left-col .button a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  position: relative;
}
.FaceIntro .container .row .left-col .button a:hover {
  background-color: white;
  opacity: 1;
}
.FaceIntro .container .row .left-col .button a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.FaceIntro .container .row .left-col .button a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.FaceIntro .container .row .right-col {
  position: relative;
  z-index: 1;
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .right-col {
    margin-top: 60px;
  }
}
.FaceIntro .container .row .right-col .img-container img {
  width: 260px;
  height: 350px;
  object-fit: cover;
  display: inline-block;
  position: relative;
  border-radius: 6px;
  filter: drop-shadow(3px 5px 14px #ffffff);
  transition: 0.3s all;
}
.FaceIntro .container .row .right-col .img-container img:hover {
  filter: none;
}
@media all and (max-width: 1200px) {
  .FaceIntro .container .row .right-col .img-container img {
    width: 220px;
    height: 310px;
  }
}
@media all and (max-width: 992px) {
  .FaceIntro .container .row .right-col .img-container img {
    width: 200px;
    height: 290px;
  }
}
@media all and (max-width: 768px) {
  .FaceIntro .container .row .right-col .img-container img {
    width: 160px;
    height: 250px;
  }
}
@media all and (max-width: 576px) {
  .FaceIntro .container .row .right-col .img-container img {
    width: 220px;
    height: 310px;
  }
}