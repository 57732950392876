@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.TreatmentOverview {
  background-color: #E99F8C;
}
.TreatmentOverview h2 {
  color: #F5EE9B;
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  text-align: center;
  padding-bottom: 60px;
}
@media all and (max-width: 1200px) {
  .TreatmentOverview h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .TreatmentOverview h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .TreatmentOverview h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .TreatmentOverview h2 {
    font-size: 24px;
  }
}
.TreatmentOverview .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .TreatmentOverview .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .TreatmentOverview .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .TreatmentOverview .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .TreatmentOverview .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .TreatmentOverview .container {
    padding: 50px 14px;
  }
}
.TreatmentOverview .container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px 40px;
}
.TreatmentOverview .container .row .card {
  width: 300px;
  background-color: #fff9f7;
  padding: 14px;
  min-height: 266px;
  border-radius: 6px;
  display: flex;
  align-items: bottom;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
}
.TreatmentOverview .container .row .card .top i {
  padding-right: 10px;
  color: #b45250;
}
.TreatmentOverview .container .row .card .top h3 {
  color: #b45250;
}
.TreatmentOverview .container .row .card .top .headline {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}
.TreatmentOverview .container .row .card .content b {
  opacity: 0.9;
}
.TreatmentOverview .container .row .card .content ul {
  margin-left: 20px;
}
.TreatmentOverview .container .row .card .button a {
  padding: 6px;
  border: 1px solid #b45250;
  display: inline-block;
  text-decoration: none;
  color: black;
  margin-top: 30px;
  transition: 0.3s all;
}
.TreatmentOverview .container .row .card .button a:hover {
  background-color: #b45250;
  color: whitesmoke;
}
.TreatmentOverview .container .button {
  display: flex;
  justify-content: center;
}
.TreatmentOverview .container .button a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  background-color: #b45250;
  color: white;
  margin-top: 60px;
  display: inline-block;
}
.TreatmentOverview .container .button a:hover {
  background-color: white;
  opacity: 1;
}
.TreatmentOverview .container .button a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.TreatmentOverview .container .button a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.TreatmentOverview .container .button a:hover {
  background-color: #b45250;
}