@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.HairTreatmntList {
  background-color: #E99F8C;
}
.HairTreatmntList .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .HairTreatmntList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .HairTreatmntList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .HairTreatmntList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .HairTreatmntList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .HairTreatmntList .container {
    padding: 50px 14px;
  }
}
.HairTreatmntList .container .accordion-section {
  list-style-position: inside;
}
.HairTreatmntList .container .accordion-section ul {
  margin: 16px 0 16px 6px;
  color: #b45250;
}
.HairTreatmntList .container .accordion-section a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  background-color: #b45250 !important;
  color: white !important;
}
.HairTreatmntList .container .accordion-section a:hover {
  background-color: white;
  opacity: 1;
}
.HairTreatmntList .container .accordion-section a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.HairTreatmntList .container .accordion-section a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.HairTreatmntList .container .accordion-section a:hover {
  opacity: 0.9;
}
.HairTreatmntList .container .accordion-section a i {
  margin-right: 6px;
}
.HairTreatmntList .container .accordion-section li {
  font-size: 18px;
}
.HairTreatmntList .container .accordion-section .dp-img {
  width: 50px;
  margin-right: 20px;
  height: 50px;
  object-fit: cover;
  border-radius: 2px;
}
.HairTreatmntList .container .accordion-section .accordion {
  display: flex;
  align-items: center;
  color: #b45250;
  cursor: pointer;
  background-color: #fff9f7;
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 24px;
  transition: 0.4s;
  position: relative;
  border-radius: 6px;
  box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
}
@media all and (max-width: 992px) {
  .HairTreatmntList .container .accordion-section .accordion {
    font-size: 18px;
  }
}
@media all and (max-width: 576px) {
  .HairTreatmntList .container .accordion-section .accordion {
    font-size: 16px;
  }
}
.HairTreatmntList .container .accordion-section .accordion:hover {
  background-color: #ffdbda;
}
.HairTreatmntList .container .accordion-section .accordion span {
  position: absolute;
  right: 20px;
}
.HairTreatmntList .container .accordion-section .accordion p {
  width: 75%;
}
.HairTreatmntList .container .accordion-section .panel {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: 100%;
  padding: 0 10px;
  border-radius: 6px;
}
.HairTreatmntList .container .accordion-section .panel p {
  font-size: 17px;
  line-height: 160%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #b45250;
}
@media all and (max-width: 992px) {
  .HairTreatmntList .container .accordion-section .panel p {
    font-size: 16px;
  }
}
@media all and (max-width: 992px) {
  .HairTreatmntList .container .accordion-section .panel p {
    font-size: 18px;
  }
}
.HairTreatmntList .container .accordion-section .panel img {
  margin: 40px 20px;
  max-width: 85%;
  width: 400px;
  max-height: 100%;
  height: 350px;
  object-fit: contain;
}
.HairTreatmntList .container .accordion-section .panel h3 {
  margin: 30px 0 16px;
  color: #b45250;
  font-size: 24px;
  text-transform: capitalize;
}
.HairTreatmntList .container .accordion-section .panel h4 {
  margin: 10px 0 6px;
  color: #b45250;
  font-size: 18px;
  text-transform: capitalize;
}
.HairTreatmntList .container .accordion-section .panel span {
  font-weight: 700;
}
.HairTreatmntList .container .accordion-section .panel.active {
  max-height: 100%;
  padding: 40px 10px;
  background-color: #fff9f7;
  margin-bottom: 5px;
  transition: all 0.3s ease-out;
}