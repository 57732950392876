@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Body .container .row {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 768px) {
  .Body .container .row {
    flex-direction: column-reverse;
  }
}
.Body .container .row .right-col {
  width: 50%;
  background-color: #fff9f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  min-height: 500px;
}
@media all and (max-width: 768px) {
  .Body .container .row .right-col {
    width: 100%;
    padding: 16px;
  }
}
.Body .container .row .right-col .content h2 {
  color: #b45250;
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
}
@media all and (max-width: 1200px) {
  .Body .container .row .right-col .content h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .Body .container .row .right-col .content h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .Body .container .row .right-col .content h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Body .container .row .right-col .content h2 {
    font-size: 24px;
  }
}
.Body .container .row .right-col .content p {
  font-size: 17px;
  line-height: 160%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .Body .container .row .right-col .content p {
    font-size: 16px;
  }
}
.Body .container .row .right-col .content a {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  background-color: #b45250;
  color: #fff9f7;
}
.Body .container .row .right-col .content a:hover {
  background-color: white;
  opacity: 1;
}
.Body .container .row .right-col .content a:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.Body .container .row .right-col .content a:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.Body .container .row .right-col .content a:hover {
  background-color: #cc6b69;
}
.Body .container .row .left-col {
  width: 50%;
  display: flex;
}
@media all and (max-width: 768px) {
  .Body .container .row .left-col {
    width: 100%;
  }
}
.Body .container .row .left-col .img-container {
  width: 100%;
}
.Body .container .row .left-col .img-container img {
  object-fit: cover;
  width: 100%;
  height: 500px;
}