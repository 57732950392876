@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.PreLoader {
  background: white;
  top: 0;
  width: 100vw;
  height: 100%;
  left: 0;
  z-index: 9999;
  position: fixed;
}
.PreLoader .middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  position: absolute;
}
.PreLoader .loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.PreLoader .loader::before {
  content: "";
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -24px;
  animation: animloader 4s ease infinite;
}
.PreLoader .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #E99F8C;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}
@keyframes animloader {
  0% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 24px #E99F8C, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 24px #E99F8C, 24px 24px #E99F8C, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 24px #E99F8C, 24px 24px #E99F8C, 24px 48px #E99F8C, 0px 48px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 24px #E99F8C, 24px 24px #E99F8C, 24px 48px #E99F8C, 0px 48px #E99F8C;
  }
  62% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px #E99F8C, 24px 48px #E99F8C, 0px 48px #E99F8C;
  }
  75% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px #E99F8C, 0px 48px #E99F8C;
  }
  87% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px #E99F8C;
  }
  100% {
    box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
  }
}
@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}

.PreLoader {
  display: none;
  /* Initially hide the preloader */
}

.PreLoader.visible {
  display: block;
  /* Show the preloader when the 'visible' class is present */
}

.PreLoader.hidden {
  display: none;
  /* Hide the preloader when the 'hidden' class is present */
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}