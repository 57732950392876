@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.HeroSection {
  height: 650px;
  width: 100%;
  background-color: #E99F8C;
}
@media all and (min-width: 1400px) {
  .HeroSection {
    height: 800px;
  }
}

.mySwiper {
  height: 100%;
  /* Set the height of the Swiper container */
}
.mySwiper .swiper-slide {
  transition-duration: 1s !important;
}
.mySwiper .swiper-slide .img-container {
  position: relative;
}
.mySwiper .swiper-slide .img-container img {
  display: block;
  width: 100%;
  height: 650px;
  object-fit: cover;
}
@media all and (min-width: 1400px) {
  .mySwiper .swiper-slide .img-container img {
    height: 800px;
  }
}
.mySwiper .swiper-slide .img-container .content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.mySwiper .swiper-slide .img-container .content .rose {
  color: whitesmoke;
}
.mySwiper .swiper-slide .img-container .content h2 {
  position: relative;
  top: 30%;
  font-size: 48px;
  width: 60%;
  font-weight: 900;
  color: whitesmoke;
  padding: 0 0 0 100px;
  text-transform: uppercase;
}
.mySwiper .swiper-slide .img-container .content h2 .best {
  color: #F5EE9B;
}
.mySwiper .swiper-slide .img-container .content h2 .look {
  color: #F5EE9B;
}
.mySwiper .swiper-slide .img-container .content h2 .makeover {
  color: #F5EE9B;
}
@media all and (max-width: 1200px) {
  .mySwiper .swiper-slide .img-container .content h2 {
    padding: 0 0 0 80px;
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .mySwiper .swiper-slide .img-container .content h2 {
    padding: 0 0 0 55px;
    font-size: 32px;
  }
}
@media all and (max-width: 768px) {
  .mySwiper .swiper-slide .img-container .content h2 {
    font-size: 30px;
    padding: 0 0 0 34px;
  }
}
@media all and (max-width: 576px) {
  .mySwiper .swiper-slide .img-container .content h2 {
    padding: 0 12px;
    width: 100%;
    font-size: 30px;
  }
}
.mySwiper .swiper-slide .img-container .content p {
  position: relative;
  top: 30%;
  width: 90%;
  color: rgb(245, 245, 245);
  padding: 6px 0 0 100px;
  font-size: 20px;
}
@media all and (max-width: 1200px) {
  .mySwiper .swiper-slide .img-container .content p {
    padding: 6px 0 0 80px;
  }
}
@media all and (max-width: 992px) {
  .mySwiper .swiper-slide .img-container .content p {
    padding: 6px 0 0 55px;
    font-size: 15px;
  }
}
@media all and (max-width: 768px) {
  .mySwiper .swiper-slide .img-container .content p {
    font-size: 14px;
    padding: 0 0 0 34px;
  }
}
@media all and (max-width: 576px) {
  .mySwiper .swiper-slide .img-container .content p {
    padding: 0 0 0 12px;
    font-size: 14px;
  }
}
.mySwiper .swiper-slide .img-container .content a {
  display: inline-block;
  color: white;
  text-decoration: none;
  font-size: 18px;
  border: 2px solid whitesmoke;
  padding: 7px 14px;
  transition: 0.3s all;
  color: white;
  position: relative;
  top: 30%;
  margin: 30px 0 0 100px;
}
.mySwiper .swiper-slide .img-container .content a:hover {
  transform: scale(1.02);
}
@media all and (max-width: 1200px) {
  .mySwiper .swiper-slide .img-container .content a {
    margin: 14px 0 0 80px;
  }
}
@media all and (max-width: 992px) {
  .mySwiper .swiper-slide .img-container .content a {
    margin: 14px 0 0 55px;
  }
}
@media all and (max-width: 768px) {
  .mySwiper .swiper-slide .img-container .content a {
    padding: 10px 12px;
    margin: 14px 0 0 34px;
  }
}
@media all and (max-width: 576px) {
  .mySwiper .swiper-slide .img-container .content a {
    margin: 14px 0 0 12px;
    padding: 6px 14px;
    font-size: 16px;
  }
}
.mySwiper .swiper-slide.swiper-slide-visible.swiper-slide-active .img-container img {
  animation: zoomOut 5s ease-in-out forwards;
}
@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.mySwiper .swiper-slide.swiper-slide-visible.swiper-slide-active .img-container h2 {
  animation: fade-up 1.3s ease-in-out forwards;
  display: inline-block;
}
@keyframes fade-up {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.mySwiper .swiper-pagination .swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  background-color: rgba(255, 255, 255, 0.719) !important;
  margin: 6px 20px 6px 0 !important;
}
@media all and (max-width: 576px) {
  .mySwiper .swiper-pagination .swiper-pagination-bullet {
    margin: 10px 12px 10px 0 !important;
    width: 12px !important;
    height: 12px !important;
  }
}