@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.CareerForm {
  background-color: #E99F8C;
}
.CareerForm .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .CareerForm .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .CareerForm .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .CareerForm .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .CareerForm .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .CareerForm .container {
    padding: 50px 14px;
  }
}
.CareerForm .container .row {
  max-width: 800px;
}
.CareerForm .container .row .input-row {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}
.CareerForm .container .row .input-row label {
  font-size: 17px;
  line-height: 160%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: rgb(255, 255, 255) !important;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 4px;
}
@media all and (max-width: 992px) {
  .CareerForm .container .row .input-row label {
    font-size: 16px;
  }
}
.CareerForm .container .row .input-row input {
  height: 50px;
  max-width: 600px;
  color: #b45250;
  padding: 5px;
  background-color: #fff9f7;
  border: none;
  font-weight: 500;
  font-size: 17px;
  border: 1px solid rgb(128, 67, 67);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.CareerForm .container .row .input-row input::placeholder {
  padding-left: 10px;
  color: #b45250;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.CareerForm .container .row .input-row input:focus {
  background-color: white;
}
.CareerForm .container .row .input-row textarea {
  height: 80px;
  max-width: 600px;
  color: #b45250;
  background-color: #fff9f7;
  border: none;
  border: 1px solid rgb(128, 67, 67);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 6px;
  font-weight: 500;
  font-size: 17px;
}
.CareerForm .container .row .input-row textarea::placeholder {
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 500;
  color: #b45250;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.CareerForm .container .row .input-row textarea:focus {
  background-color: white;
}
.CareerForm .container .row .input-row select {
  height: 50px;
  max-width: 600px;
  color: #b45250;
  background-color: #fff9f7;
  font-weight: 500;
  font-size: 17px;
  padding: 6px;
}
.CareerForm .container .row .input-row select:focus {
  background-color: white;
}
.CareerForm .container .row button {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  border: none;
  font-size: 17px;
  font-weight: 600;
}
.CareerForm .container .row button:hover {
  background-color: white;
  opacity: 1;
}
.CareerForm .container .row button:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.CareerForm .container .row button:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.CareerForm .container .row .successMessage {
  display: none;
}
.CareerForm .container .row .successMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(0, 138, 0);
  margin-left: 10px;
  font-size: 18px;
  font-weight: 700;
}
.CareerForm .container .row .errorMessage {
  display: none;
}
.CareerForm .container .row .errorMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(255, 74, 74);
  font-size: 18px;
  font-weight: 700;
}