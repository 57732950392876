@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.EmbedList {
  margin-top: -140px;
  background-color: rgb(255, 248, 248);
}
.EmbedList .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .EmbedList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .EmbedList .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .EmbedList .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .EmbedList .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .EmbedList .container {
    padding: 50px 14px;
  }
}
.EmbedList .container h2 {
  text-align: center;
  color: #b45250;
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  padding-bottom: 60px;
}
@media all and (max-width: 1200px) {
  .EmbedList .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .EmbedList .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .EmbedList .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .EmbedList .container h2 {
    font-size: 24px;
  }
}
.EmbedList .container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}
.EmbedList .container iframe {
  max-width: 100%;
}