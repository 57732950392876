@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Blogs {
  background-color: #fff9f7;
}

.BlogList {
  background-color: rgb(255, 248, 248);
  position: relative;
}
.BlogList .container {
  padding: 56px 100px;
}
@media all and (min-width: 2000px) {
  .BlogList .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .BlogList .container {
    padding: 56px 80px;
  }
}
@media all and (max-width: 992px) {
  .BlogList .container {
    padding: 56px 50px;
  }
}
@media all and (max-width: 768px) {
  .BlogList .container {
    padding: 56px 32px;
  }
}
@media all and (max-width: 576px) {
  .BlogList .container {
    padding: 36px 14px;
  }
}
.BlogList .container .row {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex-wrap: wrap;
}
.BlogList .container .row .card {
  background-color: white;
  width: 300px;
  transition: all 0.2s ease-in;
  padding: 6px;
  text-align: center;
  box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 12px -9px rgba(0, 0, 0, 0.75);
}
.BlogList .container .row .card a {
  text-decoration: none;
  font-weight: 400;
}
.BlogList .container .row .card .img-container img {
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.BlogList .container .row .card .content {
  padding: 12px 0;
}
.BlogList .container .row .card .content a {
  text-decoration: none;
  color: #b45250;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
  display: inline-block;
}
.BlogList .container .row .card .content p {
  color: black;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.BlogList .container .row .card .content .bottom-content {
  display: flex;
  justify-content: center;
}
.BlogList .container .row .card .content .bottom-content .editor-dp {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}
.BlogList .container .row .no-blogs-message {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.BlogList .container .row .no-blogs-message h3 {
  font-size: 22px;
  color: #b45250;
}
.BlogList .container .load_more {
  display: flex;
  justify-content: center;
  padding: 50px 0 0;
}
.BlogList .container .load_more .loadMore_btn {
  display: inline-block;
  padding: 12px 14px;
  text-decoration: none;
  background-color: #fff9f7;
  color: #b45250;
  text-transform: uppercase;
  font-weight: 500;
  margin: 24px 0;
  transition: 0.3s all;
  position: relative;
  opacity: 0.9;
  border: none;
}
.BlogList .container .load_more .loadMore_btn:hover {
  background-color: white;
  opacity: 1;
}
.BlogList .container .load_more .loadMore_btn:hover::before {
  opacity: 0;
  top: 0;
  left: 0;
  transition: 0.3s all;
}
.BlogList .container .load_more .loadMore_btn:hover span {
  position: relative;
  left: 0;
  top: 0;
}
.BlogList .category-buttons {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #E99F8C;
  margin-bottom: 50px;
  background-color: rgb(255, 246, 246);
}
.BlogList .category-buttons button {
  border: none;
  padding: 18px 16px;
  margin: 0 1px;
  transition: 0.2s background-color;
  font-size: 16px;
  font-family: "Lora", serif;
  font-weight: 600;
}
@media all and (max-width: 576px) {
  .BlogList .category-buttons button {
    padding: 14px 6px;
    font-size: 12px;
  }
}
.BlogList .category-buttons button:hover {
  cursor: pointer;
}
.BlogList .category-buttons .active {
  background-color: #E99F8C;
  border-bottom: 4px solid #b45250;
}
.BlogList .category-buttons .not-active {
  background-color: rgb(255, 255, 255);
}
.BlogList .category-buttons .not-active:hover {
  background-color: rgba(233, 159, 140, 0.3019607843);
}

.spinning_loading {
  background-color: #fff9f7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30vh 0;
}
.spinning_loading .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinning_loading .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #b45250;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.spinning_loading .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.spinning_loading .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.spinning_loading .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.spinning_loading .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.spinning_loading .container {
  height: 200px;
}