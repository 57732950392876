@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.WhyChooseUs {
  background: url("../../public/img/girl-keeping-hands-on-face.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  margin-top: -150px;
  padding-top: 50px;
}
@media all and (max-width: 768px) {
  .WhyChooseUs {
    margin-top: 0;
    padding-top: 0;
  }
}
.WhyChooseUs::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(233, 159, 140, 0.6039215686);
  z-index: -1;
}
.WhyChooseUs .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .WhyChooseUs .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .WhyChooseUs .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .WhyChooseUs .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container {
    padding: 50px 14px;
  }
}
.WhyChooseUs .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  text-align: center;
  color: #F5EE9B;
  padding-bottom: 60px;
}
@media all and (max-width: 1200px) {
  .WhyChooseUs .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .WhyChooseUs .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .WhyChooseUs .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container h2 {
    font-size: 24px;
  }
}
.WhyChooseUs .container .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-bottom: 10px;
  /* .right-col animation styling */
}
@media all and (max-width: 768px) {
  .WhyChooseUs .container .row {
    grid-template-columns: 1fr;
  }
}
.WhyChooseUs .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.WhyChooseUs .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .WhyChooseUs .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.WhyChooseUs .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.WhyChooseUs .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .WhyChooseUs .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .WhyChooseUs .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.WhyChooseUs .container .row .card {
  background-color: #fff9f7;
  padding: 14px;
  border-radius: 10px;
  min-height: 200px;
}
.WhyChooseUs .container .row .card .headline {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.WhyChooseUs .container .row .card .headline i {
  padding-right: 10px;
  font-size: 20px;
  color: #b45250;
}
.WhyChooseUs .container .row .card .headline h3 {
  color: #b45250;
}
.WhyChooseUs .container .row .card p {
  font-size: 17px;
  line-height: 150%;
}