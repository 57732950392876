@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Team {
  background-color: #E99F8C;
}
.Team .container {
  padding: 90px 100px;
}
@media all and (min-width: 2000px) {
  .Team .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Team .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Team .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Team .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Team .container {
    padding: 50px 14px;
  }
}
.Team .container h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  text-align: center;
  padding-bottom: 60px;
  color: #F5EE9B;
}
@media all and (max-width: 1200px) {
  .Team .container h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .Team .container h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .Team .container h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Team .container h2 {
    font-size: 24px;
  }
}
.Team .container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;
}
.Team .container .row .card {
  width: 500px;
  position: relative;
  padding: 18px 14px;
  border-radius: 6px;
  background-color: #fff9f7;
  text-align: center;
  -webkit-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
}
.Team .container .row .card .img-container img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
}
.Team .container .row .card .content h3 {
  color: #b45250;
  font-size: 20px;
  margin-top: 8px;
}
.Team .container .row .card .content h4 {
  color: #b45250;
  padding-bottom: 10px;
  font-weight: 500;
}
.Team .container .row .card .content p {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}