@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Values {
  background-color: #E99F8C;
}
.Values h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lora", serif;
  font-weight: 700;
  text-align: center;
  color: #F5EE9B;
}
@media all and (max-width: 1200px) {
  .Values h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .Values h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .Values h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .Values h2 {
    font-size: 24px;
  }
}
.Values .container {
  padding: 56px 100px;
}
@media all and (min-width: 2000px) {
  .Values .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Values .container {
    padding: 56px 80px;
  }
}
@media all and (max-width: 992px) {
  .Values .container {
    padding: 56px 50px;
  }
}
@media all and (max-width: 768px) {
  .Values .container {
    padding: 56px 32px;
  }
}
@media all and (max-width: 576px) {
  .Values .container {
    padding: 36px 14px;
  }
}
.Values .container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px 20px;
}
.Values .container .row .card {
  width: 315px;
  text-align: center;
  background-color: #fff9f7;
  padding: 18px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 18px -8px rgba(0, 0, 0, 0.75);
}
.Values .container .row .card h3 {
  color: #b45250;
  margin-bottom: 6px;
}
.Values .container .row .card p {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
.Values .container .row .card img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin: 6px 0;
  transition: 0.3s all;
}
.Values .container .row .card:hover img {
  transform: scale(1.06);
}
.Values .container .row .card:hover::before {
  animation: slide-shine 1.2s ease-in-out;
}